/*======================
    404 page
=======================*/


.page_404 {
    padding: 120px 0px 0px 0px;
    background: #fff;
    font-family: 'Arvo', serif;
    margin: 0 auto;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url('https://ik.imagekit.io/kiriti369/whatsnxt-blog/404.gif');
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}

.constant_box_404 {
    margin-top: 10px;
}